import 'bootstrap/dist/css/bootstrap.min.css';

import Maps from './components/maps/index.js'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import InputMask from 'react-input-mask';
import React, { useEffect, useState } from 'react';

import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faDiagramProject, faUserNurse, faRankingStar, faChartLine, faFolderTree, faLaptopMedical, faDisplay, faWhatsapp } from '@fortawesome/free-solid-svg-icons';
import { ClipboardPen, MonitorStop } from 'lucide-react';

import emailjs from '@emailjs/browser';

import './App.css';

function App() {

  const [LogoParceiro, setLogoParceiro] = useState(null);
  const [TextParceiro, setTextParceiro] = useState();


  const [parceiro, setParceiro] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [errorName, setErrorName] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [errorTel, setErrorTel] = useState();
  const [errorMensagem, setErrorMensagem] = useState();


  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const parceiro = params.get('parceiro');
    setParceiro(parceiro);
    const parceiroLink = 'http://www.seprosp.org.br';
    if (parceiro != null){
      setLogoParceiro(`<a href='${parceiroLink}' target='_blank' rel='noopener noreferrer'><img src='https://www.baal.com.br/img/${parceiro.toLowerCase()}/logo.png' className='w-40 h-30' /></a>`);
      setTextParceiro(`<h1>Bem-vindo <BR> ASSOCIADOS, AFILIADOS E PARCEIROS ${parceiro}! <BR> Entre em <a href="#contato">contato</a> conosco para ter acesso a Benefícios!`)
    }
    else{
      setLogoParceiro(`<div></div>`);
      setTextParceiro(`<h1 className='text-white text-4xl text-center'>Grupo Virtus</h1>`);
    }
  }, []);

  function sendEmail(e){
    e.preventDefault();
    if(name === "" ){
      setErrorName("Campo Nome deve estar preechido");
    }
    else{
      setErrorName("");
    }

    if(email === ""){
      setErrorEmail("Campo Email deve estar preechido");
    }
    else{
      setErrorEmail("");
    }

    if (!tel || !/^\(\d{2}\)\d{4,5}-\d{4}$/.test(tel)) {
      setErrorTel("Campo Telefone deve estar preechido");
    }
    else{
      setErrorTel("");
    }

    if(mensagem === ""){
      setErrorMensagem("Campo mensagem deve estar preechido");
    }
    else{
      setErrorMensagem("")
    }

    if(name !== "" && email !== "" && tel !== "" && mensagem !== ""){
      setErrorName("");
      setErrorEmail("");
      setErrorTel("");
      setErrorMensagem("");
      const templateParms = {
        from_name: name,
        from_parceiro: parceiro,
        from_email: email,
        from_fone: tel,
        mensagem: mensagem
      }
  
      emailjs.send("service_vtvgilm", "template_yvxhvjw" , templateParms, "4pTcqqAInnJJcHA7N" )
      .then( (res) => {
        alert("email enviado");
        setName("");
        setEmail("");
        setTel("");
        setMensagem("");
      }, (err) =>{
        alert("erro tenta mais tarde novamente")
      });
    }


  }

  return (
    <div>
      <nav className='fixed top-0 left-0 right-0 z-50 bg-white d-flex justify-between items-center py-4 pr-16 pl-16 mr-16v border-b border-black'>
        <div className='ml-0 sm:ml-5 md:ml-10 lg:ml-10 xl:ml-10 2xl:ml-10 sm:mr-5 md:mr-10 mr-8'>
          <a href='https://www.virtusocupacional.com.br' className='' target="_blank" rel="noopener noreferrer"><img src='https://baal.com.br/img/logo.png' className='w-40 h-30' /></a>
        </div>
        <div className='w-40 h-30'>
          <div dangerouslySetInnerHTML={{ __html: LogoParceiro }} />
        </div>
      </nav>
      <div className='align-middle text-cente pt-12 pb-14 bg-virtus h-auto block heig' >
          <div className='w-4/5 m-auto rounded-3xl background-0-0-0-6'>
            <div className='py-14 w-4/5 m-auto'>
              <div className='text-white text-4xl text-center mt-20' dangerouslySetInnerHTML={{ __html: TextParceiro }} />
              
            </div>
            <div className='flex text-center justify-center my-auto'>
              <p className='text-white w-2/3 my-auto text-2xl'>
                Com a nossa tecnologia, desenvolvemos soluções eficazes para a rotina diária do cliente.
                Nosso propósito é garantir qualidade em tudo que uma empresa precisa para cuidar da saúde de seus colaboradores,
                desde o ato da admissão até todas as etapas da gestão ocupacional.
              </p>
            </div>
            <div className='block w-full text-center justify-center mt-20 '>
              <div className='sm:block md:block lg:flex mt-12'>
              <div className='pr-4 pl-4 text-center justify-center sm:w-full md:w-full lg:w-1/4 text-white items-center block'>
                  <div className='block h-24 m-auto content-center'>
                    <FontAwesomeIcon icon={faChartLine} className='w-1/4 my-auto h-24 block' />
                  </div>
                  <div className=' block'>
                  <h6 className='text-3xl sm:p-4 md:p-4 lg:p-2 sm:h-20 md:h-20 lg:h-48'>
                      Uma nova Medicina do Trabalho
                    </h6>
                    <h6 className='text-xl p-2 sm:h-20 md:h-20 lg:h-28'>
                      Medicina do Trabalho: O bem-estar do trabalhador
                    </h6>
                  </div>
                  <div className=' block p-2 h-auto'>
                    <ul className="list-disc text-left block h-auto sm:m-auto md:m-auto sm:w-2/3 md:w-2/3 text-lg">
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Mais do que cumprir um determinação legal, a empresa que preza pela saúde e pela integridade física de seus colaboradores atua como agente do desenvolvimento social.</li>   
                    </ul>             
                  </div>
                </div>
                <div className='pr-4 pl-4 text-center justify-center sm:w-full md:w-full lg:w-1/4 text-white items-center block'>
                  <div className=' block h-24 m-auto content-center'>
                    <FontAwesomeIcon icon={faRankingStar} className='w-1/4 my-auto h-24' />
                  </div>
                  <div className=' block'>
                    <h6 className='text-3xl sm:p-4 md:p-4 lg:p-2 sm:h-20 md:h-20 lg:h-48'>
                      Medicina e Segurança do Trabalho
                    </h6>
                    <h6 className='text-xl p-2 sm:h-20 md:h-20 lg:h-28'>
                      Laudos Técnicos | Consultoria Técnica | Treinamentos
                    </h6>
                  </div>
                  <div className=' flex p-2 h-auto'>
                    <ul className="list-disc text-left block h-auto sm:m-auto md:m-auto sm:w-2/3 md:w-2/3 text-lg">
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Oferecemos serviços especializados com foco na prevenção de acidentes de trabalho, aumento de produtividade e aprimoramento de serviços.</li>
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Nosso objetivo é fornecer aos nossos clientes serviços com propriedade e eficiência, atendendo aos requisitos das Normas Regulamentadoras do Ministério do Trabalho e Emprego.</li>
                    </ul>
                  </div>
                </div>
                <div className='pr-4 pl-4 text-center justify-center sm:w-full md:w-full lg:w-1/4 text-white items-center block'>
                  <div className=' block m-auto content-center'>
                    <FontAwesomeIcon icon={faUserNurse} className='w-1/4 my-auto h-24' />
                  </div>
                  <div className=' block'>
                    <h6 className='text-3xl sm:p-4 md:p-4 lg:p-2 sm:h-20 md:h-20 lg:h-48'>
                      Perícias Médicas
                    </h6>
                    <h6 className='text-xl p-2 sm:h-20 md:h-20 lg:h-28'>
                      Avaliação por profissional médico
                    </h6>
                  </div>
                  <div className='block p-2'>
                    <ul className="list-disc text-left block h-auto sm:m-auto md:m-auto sm:w-2/3 md:w-2/3 text-lg">
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Apoio que sua Empresa precisa</li>
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>A mais especializada, inovadora e experiente assessoria técnica em perícia médica.</li>
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Nossa equipe está pronta e preparade para lhe apresentar propostas personalizadas de serviços que atenda sua real necessidade em perícia medico.</li>
                    </ul>
                  </div>
                </div>
                <div className='pr-4 pl-4 text-center justify-center sm:w-full md:w-full lg:w-1/4 text-white items-center block'>
                  <div className=' flex m-auto content-center justify-center'>
                      <FontAwesomeIcon icon={faDiagramProject} className='w-1/4 my-auto h-24' />
                  </div>
                  <div className=' block'>
                    <h6 className='text-3xl sm:p-4 md:p-4 lg:p-2 sm:h-20 md:h-20 lg:h-48'>
                      Rede de Clinicas Credenciadas em todo o Brasil
                    </h6>
                    <h6 className='text-xl p-2 sm:h-20 md:h-20 lg:h-28'>
                      Exames Complementares e Atendimento in Company
                    </h6>
                  </div>
                  <div className='flex p-2'>
                    <ul className="list-disc text-left block h-auto sm:m-auto md:m-auto sm:w-2/3 md:w-2/3 text-lg">
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Todos os exames são realizados no mesmo local e mesmo período, deixando nosso atendimento mais dinâmico e prático.</li>
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Estrutura complete para attender suas necessidades em exames ocupacionais e laboratoriais.</li>
                      <li className='sm:mt-1 md:mt-2 lg:mt-4 mb-4'>Contamos com equipe especializada em serviços externos para avaliar trabalhadores diretamente em seu ambiente de trabalho.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div className='bg-zinc-400 pt-12 pb-14 ml-auto h-auto block bg-virtus2'>
        <div className=' w-4/5 mx-auto flex justify-center items-center rounded-3xl background-0-0-0-6 text-white h-auto'>
          <div className='text-center h-auto'>
            <div className=''>
              <h1 className='text-4xl p-10'>Produtos & Serviços</h1>
            </div>
            
            <div className='sm:block md:block lg:block xl:flex align-top lg:text-center h-auto'>
              <div className='sm:w-auto md:w-auto lg:w-auto xl:w-1/5 text-center p-4 h-auto'>
                <div className='h-24'>
                  <MonitorStop className='size-20' />
                </div>
                <div className='text-center h-auto'>
                  <h2 className='text-3xl '>Portal do Cliente</h2>
                </div>
                <div className='flex p-3'>
                  <ul className="list-disc m-auto sm:text-center md:text-center lg:text-start block">
                    <li>Gestão Médica</li>
                    <li>Gestão Técnica</li>
                    <li>CIPA</li>
                    <li>EPIs</li>
                    <li>eSocial</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              
              <div className='sm:w-auto md:w-auto lg:w-auto xl:w-1/5 text-center p-4'>
                <div className='text-center h-24'>
                  <FontAwesomeIcon icon={faFolderTree} className='h-20' />
                </div>
                <div className='text-center h-auto'>
                  <h2 className='text-3xl '>Gerenciamento de Documentos</h2>
                </div>
                <div className=' flex p-3'>
                  <ul className="list-disc m-auto text-start block">
                    <li>PCMSO</li>
                    <li>PGR</li>
                    <li>LTCAT</li>
                    <li>ASO</li>
                    <li>Exames</li>
                    <li>Complementares</li>
                  </ul>
                </div>
              </div>
              
              <div className='sm:w-auto md:w-auto lg:w-auto xl:w-1/5 text-center p-4'>
                <div className='text-center h-24'>
                  <FontAwesomeIcon icon={faLaptopMedical} className='h-20' />
                </div>
                <div className='text-center h-auto'>
                  <h2 className='text-3xl '>Gestão Médica & Técnica</h2>
                </div>
                <div className='flex p-3'>
                  <ul className="list-disc m-auto text-left block">
                    <li>Gestão Médica e Técnica das Exigências de Medicina e Saúde Ocupacional</li>
                  </ul>
                </div>
              </div>

              <div className='sm:w-auto md:w-auto lg:w-auto xl:w-1/5 text-center p-4'>
                    <div className=' text-centerh-24'>
                      <ClipboardPen className='size-20' />
                    </div>
                    <div className='text-center h-auto'>
                      <h2 className='text-3xl '>Treinamentos Presenciais e a Distância (EAD)</h2>
                    </div>
                    <div className='flex p-3'>
                      <ul className="list-disc m-auto text-left block">
                        <li>Treinamentos à Distância com Gamificação &  Avaliações</li>
                      </ul>
                    </div>
              </div>
            
              <div className='sm:w-auto md:w-auto lg:w-auto xl:w-1/5 text-center p-4'>
                <div className='text-center h-24'>
                  <FontAwesomeIcon icon={faDisplay} className='h-20' />
                </div>
                <div className='text-center h-auto'>
                  <h2 className='text-3xl '>Assessoria & Telemedicina</h2>
                </div>
                <div className='flex p-6'>
                  <ul className="list-disc m-auto text-left block">
                    <li>Avaliações</li>
                    <li>Laudos</li>
                    <li>Perícias</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='align-middle text-center bg-virtus3 pt-12 pb-14 text-white'>
        <div className='w-4/5 m-auto rounded-3xl h-auto background-0-0-0-6'>
          <div className='text-center'>
            <h2 className='text-3xl h-16 m-6 p-6 h-auto'>Uma nova Medicina do Trabalho </h2>
          </div>
          <div className='lg:flex md:flex sm:block items-center'>
            <div className='w-1/2 justify-center items-center m-auto  sm:pt-6 md:pt-3 lg:pt-0 xl:pt-0 2xl:pt-0 '>
              <img src="https://www.baal.com.br/img/lamp.png"></img>
            </div>
            <div className='lg:w-1/2 md:w-1/2 sm:w-full items-center'>
              <ul className='list-disc w-full h-auto m-auto p-8 lg:text-start md:text-start sm:text-center '>
                <li className="h-1/5 text-xl m-1 p-2">Saúde Ocupacional e o nosso négocio;</li>
                <li className="h-1/5 text-xl m-1 p-2">Gente cuidando de Gente;</li>
                <li className="h-1/5 text-xl m-1 p-2">Tecnologia a nosso favor;</li>
                <li className="h-1/5 text-xl m-1 p-2">Contamos com um time com senso de dono;</li>
                <li className="h-1/5 text-xl m-1 p-2">Possuímos uma cultura fora da caixa, além de parceiros a nível nacional, todos com processos bem estruturados.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='align-middle text-center bg-virtus pt-12 pb-14 text-white'>
        <div className='w-4/5 background-0-0-0-6 m-auto rounded-3xl'>
          <div className='flex'>
            <div className='flex justify-end m-auto h-auto p-10 w-full sm:w-3/4 md:w-2/4 lg:w-1/2'>
              <img src='http://www.baal.com.br/img/medicina.png' className='h-auto w-full' id='image'></img>
            </div>
            <div className='flex justify-start items-center w-full sm:w-3/4 md:w-3/4 lg:w-1/2'>
              <ul className='h-auto list-disc text-start max-h-[100px] sm:max-h-[200px] md:max-h-[200px] lg:max-h-[400px] xl:max-h-[500px] 2xl:max-h-[850px] w-full overflow-auto' id='Scroll-personilizado'>
                <li className="p-2 text-xs lg:text-xl">PCMSO (Programa de Controle Médico de Saúde Ocupacional)</li>
                <li className="p-2 text-xs lg:text-xl">Gestão Médica Ocupacional</li>
                <li className="p-2 text-xs lg:text-xl">Perícia de Laudos Técnicos</li>
                <li className="p-2 text-xs lg:text-xl">Programas de Promoção à Saúde</li>
                <li className="p-2 text-xs lg:text-xl">Campanhas de Vacinação</li>
                <li className="p-2 text-xs lg:text-xl">Campanha Médica in Company</li>
                <li className="p-2 text-xs lg:text-xl">Gestão de Riscos e Controle de Acidentes</li>
                <li className="p-2 text-xs lg:text-xl">Coordenação Médica</li>
                <li className="p-2 text-xs lg:text-xl">SIPAT (Semana Interna de Prevenção de Acidentes de Trabalho)</li>
                <li className="p-2 text-xs lg:text-xl">Diminuição de Absenteísmo</li>
                <li className="p-2 text-xs lg:text-xl">Emissão de PPP (Perfil Profissiográfico Previdenciário)</li>
                <li className="p-2 text-xs lg:text-xl">Desenvolvimento Laboral</li>
                <li className="p-2 text-xs lg:text-xl">Gestão Ambulatorial</li>
                <li className="p-2 text-xs lg:text-xl">Exames Clínicos e Complementares</li>
                <li className="p-2 text-xs lg:text-xl">Laudo Ergonômico</li>
                <li className="p-2 text-xs lg:text-xl">Palestras sobre Saúde e Vida</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      


      <div className='align-middle text-center bg-virtus2 pt-12 pb-14 text-white'>
        <div className='w-4/5 background-0-0-0-6 m-auto rounded-3xl'>
          <div className='flex'>
            <div className='flex justify-end m-auto h-auto p-10 w-1/2'>
              <img src='http://www.baal.com.br/img/seguranca.png' className='h-auto w-full'></img>
            </div>
            <div className='flex justify-start items-center w-1/2 '>
            <ul className='h-auto list-disc text-start max-h-[200px] sm:max-h-[400px] md:max-h-[350px] lg:max-h-[400px] xl:max-h-[500px] 2xl:max-h-[850px] w-full overflow-auto' id='Scroll-personilizado'>
                <li className='p-2 text-xs lg:text-xl'>PGR (Programa de Gerenciamento de Riscos)</li>
                <li className='p-2 text-xs lg:text-xl'>CIPA (Comissão Interna de Prevenção de Acidentes)</li>
                <li className='p-2 text-xs lg:text-xl'>Brigada de Incêndio</li>
                <li className='p-2 text-xs lg:text-xl'>Avaliação de Posto de Trabalho</li>
                <li className='p-2 text-xs lg:text-xl'>Terceirização Técnica</li>
                <li className='p-2 text-xs lg:text-xl'>Mapa de Risco</li>
                <li className='p-2 text-xs lg:text-xl'>LTCAT (Laudo Técnico das Condições Ambientais do Trabalho)</li>
                <li className='p-2 text-xs lg:text-xl'>Atendimento às Exigências Legais</li>
                <li className='p-2 text-xs lg:text-xl'>Controle de EPI (Equipamentos de Proteção Individual)</li>
                <li className='p-2 text-xs lg:text-xl'>Treinamentos Obrigatórios</li>
                <li className='p-2 text-xs lg:text-xl'>Gestão Operacional</li>
                <li className='p-2 text-xs lg:text-xl'>Coordenação Técnica</li>
                <li className='p-2 text-xs lg:text-xl'>Dosimetria</li>
                <li className='p-2 text-xs lg:text-xl'>Capacidade Técnica Nacional</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      

      
      <div className='align-middle text-center bg-virtus3 pt-12 pb-14 text-white'>
        <div className='w-4/5 background-0-0-0-6 m-auto rounded-3xl'>
          <Form onSubmit={sendEmail} id="contato">
            <div>
                <h1 className='text-xl sm:pt-4 md:pt-4 lg:pt-3 xl:pt-5 2xl:pt-5 sm:mb-4 md:mb-4 lg:mb-2 xl:mb-2 2xl:mb-0 sm:text-xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl'>CONTATO</h1>
            </div>
              <div className='lg:flex lg:items-center lg:justify-center md:block md:text-center sm:block sm:text-center' >
             
              <div className=' sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-start m-11 lg:mx-0 lg:my-0 md:mx-auto sm:mx-auto' >
                <div className='lg:ml-18 md:ml-10 sm:ml-0 sm:text-left'>
                  <div>
                    <h2 className='sm:text-lg md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl text-lg m-2 text-virtus1 font-bold '>INFORMAÇÕES:</h2>
                    <h3 className='sm:mb-4 md:bm-4 lg:mb-2 xl:mb-2 2xl:mb-0'><strong>ENDEREÇO:</strong></h3>
                    <p className='text-xl m-2'>Rua do Bosque, 1621 , Barra Funda –  São Paulo</p>
                    <p className='text-xl m-2'>CEP: 1136001</p>
                  </div>
                  <div>
                    <h3 className='sm:mb-4 md:bm-4 lg:mb-2 xl:mb-2 2xl:mb-0'><strong>TELEFONE:</strong></h3>
                    <p className='text-xl m-2'>(11) 3675-7241</p>
                    <p className='text-xl m-2'>(11) 9-9492-1141</p>
                  </div>
                  <div>
                    <h3 className='sm:mb-4 md:bm-4 lg:mb-2 xl:mb-2 2xl:mb-0'><strong>E-MAIL:</strong></h3>
                    <p className='text-xl m-2'>comercial<wbr />@virtusocupacional<wbr />.com.br</p>
                  </div>
                  <div>
                    <h3 className='sm:mb-4 md:bm-4 lg:mb-2 xl:mb-2 2xl:mb-0'><strong>HORÁRIO DE FUNCIONAMENTO:</strong></h3>
                    <p className='text-xl m-2'>Segunda à Quinta – 8h às 18h</p>
                    <p className='text-xl m-2'>Sexta – 8h às 17h</p>
                  </div>
                </div>
              </div>
              <div className='w-1/2 text-centre m-11 lg:mx-0 lg:my-0 md:mx-auto sm:mx-auto'>
                <div className=''>
                  <Form.Group className="mb-3 md:mr-5 sm:mr-5 lg:mr-12" controlId="formBasicEmail">
                    <Form.Label className='flex'>Nome <p className='text-red-600'>*</p></Form.Label>
                    <Form.Control type="text" placeholder="Escreva seu nome e sobrenome"  isInvalid={!!errorName} onChange={(e)=> setName(e.target.value)}  value={name}/>
                    <Form.Control.Feedback type="invalid">
                      {errorName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3 md:mr-5 sm:mr-5 lg:mr-12" controlId="formBasicEmail">
                    <Form.Label className='flex'>Email<p className='text-red-600'>*</p></Form.Label>
                    <Form.Control type="mail" placeholder="Escreva seu Email" isInvalid={!!errorEmail}  value={email} onChange={(e)=> setEmail(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      {errorEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3 md:mr-5 sm:mr-5 lg:mr-12" controlId="formBasicEmail">
                    <Form.Label className='flex'>Telefone<p className='text-red-600'>*</p></Form.Label>
                    <InputMask
                      mask="(99)99999-9999"
                      value={tel}
                      onChange={(e) => setTel(e.target.value)}
                    >
                      {(inputProps) => (
                        <Form.Control
                          type="tel"
                          placeholder="(dd)99999-9999"
                          isInvalid={!!errorTel}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    <Form.Control.Feedback type="invalid">
                      {errorTel}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3 md:mr-5 sm:mr-5 lg:mr-12" controlId="formBasicEmail">
                    <Form.Label className='flex'>Mensagem<p className='text-red-600'>*</p></Form.Label>
                    <Form.Control as="textarea" type="text" placeholder="Escreva sua Mensagem para nos" isInvalid={!!errorMensagem}  value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      {errorMensagem}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='w-auto'>
                  <button className='btn btn-success  rounded-xl'>Enviar a mensagem</button>
                </div>
              </div>  
            </div>
          </Form>
        </div>
      </div>

      <div className='fixed bottom-8 right-8 w-16 h-16 rounded-full bg-green-500 flex justify-center'>
        <div className='w-14 h-14 p-1 ml-1'>
          <a href='https://web.whatsapp.com/send?phone=5511994921141&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20VirtusOcupacional.' target='_blank' rel='noopener noreferrer' ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg></a>
        </div>
      </div>
    </div>
  );
}

export default App;
